$theme-colors: (
  'primary': rgb(31, 30, 29),
  'secondary': #d07621,
  'tsr-blue': #2185d0,
  'tsr-red': #db2828,
  'tsr-facebook': rgb(56, 88, 152),
  'tsr-twitter': rgb(29, 155, 240),
  'tsr-youtube': rgb(204, 0, 0)
);

@import '~bootstrap/scss/bootstrap';
